<template>
  <div class="border border-gray-300 px-4 py-2 mb-4 sm:mb-2 rounded shadow hover:shadow-none hover:border-gray-400">
    <div class="flex md:items-center flex-col sm:flex-row">
      <div class="mr-2 flex-grow flex flex-col sm:flex-row md:items-center ">
        <div class="mr-2 text-lg mb-3 sm:mb-0">
          {{ schedule.startingTimeFormatted }}
        </div>
        <div class="sm:ml-2 flex flex-col sm:flex-row md:items-center ">
          <div>{{ schedule.subjectName }}</div>
          <div class="hidden sm:block mx-3">&mdash;</div>
          <div>
            <position :value="schedule.serial"></position> {{schedule.type}}
          </div>
        </div>
      </div>
      <div class="sm:ml-3 mt-5 sm:mt-0">

        <btn :disabled="viewBtnDisabled"
             :router-path="`/my/course/${schedule.programId}/content/${schedule.contentId}/${subjectName}/${scheduleType}/${schedule.serial}`"
             variant="info">View
        </btn>

      </div>
    </div>
  </div>
</template>

<script>
import Position from "../../exams/components/position";
import Btn from "../../../../components/btn";
export default {
  name: "schedule-item",
  components: {Btn, Position},
  props: {
    schedule: { type: Object, default: {}},
    notAdmittedOrLoggedIn: { type: Boolean, default: false }
  },
  methods:{
    format_uri( uri ){
      return encodeURI( String( uri ).replaceAll(' ', '-').trim().toLowerCase() );
    }
  },
  computed: {

    viewBtnDisabled(){
      if( this.notAdmittedOrLoggedIn ) {
        return true;
      }
      return !this.schedule.hasContent;
    },

    subjectName(){
      return this.format_uri( this.schedule.subjectName );
    },

    scheduleType(){
      // return this.schedule.type;
      return this.format_uri( this.schedule.type ) ;
    }

  }
}
</script>

<style scoped>

</style>