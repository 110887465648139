<template>
  <div>
    <h1 class="text-xl sm:text-4xl md:text-center py-5 md:py-10">{{ !program ? 'Loading...':`${program.courseName} - ${program.batchName}` }}</h1>

    <div class="relative w-full max-w-6xl mx-auto my-3">
      <div class="w-full my-3">
        <div class="my-3 relative" style="min-height: 400px">

          <overly class="rounded" background="bg-blue-100" v-if="loading">
            <spinner class="text-red-600 h-10 w-10"></spinner>
            Loading...
          </overly>
          <div class="flex py-4 md:gap-4 px-0 md:px-3 py-4 w-full" v-fade-in="!loading">

            <div class="flex-grow flex flex-col" >
              <div class="flex w-full text-2xl font-bold mb-2">
                <div class="flex-grow flex">
                  <div>Schedules</div>
                  <btn variant="warning" class="ml-4" v-if="notAdmitted" @click.prevent="enrollToProgram( { programItem: program } )">Enroll</btn>
                </div>
                <div class="relative">

                  <btn class="w-30" @click="toggleSubjects">
                    Filter
                    <svg fill="currentColor" viewBox="0 0 20 20" class="w-5 inline-block">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                    </svg>
                  </btn>

                  <div class="flex flex-col absolute right-0 w-60 -top-100 bg-white shadow rounded p-4" v-if="mobSubVisible">
                    <div class="text-base">Subject</div>
                    <div class="text-gray-400 text-xs mb-2">Click subject to filter</div>

                    <div class="border flex flex-col px-1 rounded">
                      <btn :border="false" v-for="subject in subjects" class="my-1 text-left" size="sm"  variant="success-outline" @click="subjectIds = subject.id">
                        <span class="absolute left-6" v-if="subjectIsSelected( subject.id )">&check;</span> {{ subject.name }}
                      </btn>
                    </div>

                  </div>

                </div>
              </div>

              <div class="w-full overflow-y-auto py-0 md:py-4 border  bg-white shadow-md" style="height: 500px" id="schedule-list">
                <template v-if="getDates.length > 0">
                  <div v-for="date in getDates">
                    <schedules-with-date :date="date" :schedules="schedulesByDate(date)" :not-admitted-or-logged-in="!(program.isAdmitted  || isLoggedIn)" />
                  </div>
                </template>
                <div v-else class="flex justify-center items-center h-full">
                  <div>Schedule Not Found</div>
                </div>
              </div>

            </div>

<!--            <div class="hidden md:block">-->
<!--              <div class="flex flex-col">-->

<!--                <div class="w-full text-2xl fonf-bold mb-2">-->
<!--                  Subjects-->
<!--                </div>-->

<!--                <div class="flex flex-col shadow bg-white">-->
<!--                  <btn v-for="subject in subjects" class="mb-4 relative" variant="success" >-->
<!--                    {{ subject.name }}-->
<!--                  </btn>-->
<!--                </div>-->

<!--              </div>-->
<!--            </div>-->

          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Overly from "../../../components/overly";
import Spinner from "../../../components/spinner";
import Btn from "../../../components/btn";
import Position from "../exams/components/position";
import filtering from "../../../mixins/filtering";
import SchedulesWithDate from "./components/schedules-with-date";

export default {
  name: "schedules",
  mixins: [filtering],
  components: {SchedulesWithDate, Position, Btn, Spinner, Overly},
  data(){
    return {
      schedules: [],
      program: null,
      dates: [],
      filteredDates: null,
      subjects: [],
      loading: true,
      mobSubVisible: false,
    }
  },
  computed:{
    getDates(){
        return Array.isArray( this.dates ) ? this.dates:[];
    },
    serial(){
      return this.$route.params.serial;
    },
    programId(){
      return this.$route.params.program_id;
    },
    notAdmitted(){
      if( this.program ) {
        return !this.program.isAdmitted;
      }
      return false;
    }
  },
  methods:{
    dateHasSchedules( date ){
      return true;
    },
    subjectIsSelected( id ){
      return Array.isArray( this.subjectIds ) ?  this.subjectIds.indexOf( String(id) ) > -1 : false;
    },
    toggleSubjects(){
      this.mobSubVisible = !this.mobSubVisible;
    },
    filteringMap( schedule ){
      return (filter, index) => {
        if( filter.key && schedule[ filter.key ] && filter.value ) {
          if( Array.isArray( filter.value ) ) {
            return filter.value.indexOf( String( schedule[ filter.key ] ) ) > -1;
          }
        }
      }
    },
    schedulesByDate( date ){

      const results = this.schedules.filter( schedule => {
          let matches = true;
          if( Array.isArray( this.filtering ) ) {
            matches = this.filtering.map( ( filter, index ) => {
              if( filter.key && schedule[ filter.key ] && filter.value ) {
                if( Array.isArray( filter.value ) ) {
                  return filter.value.indexOf( String( schedule[ filter.key ] ) ) > -1;
                }
              }
            });
            matches = matches.indexOf( false ) === -1;
          }

          return schedule.startingDateFormatted === date && matches;
      });

      return results;

    },
    loadSchedules( ){
      const params = {};

      if( this.programId  ) {

        this.$store.dispatch( 'batch/schedules', {
          programId: this.programId,
          success: ({data}) => {

            if( !data.isLoggedIn ) {
              this.clearLoginLocalStorageAndLogout();
            }

            this.schedules = data.schedules;
            this.program = data.program;
            this.subjects = data.subjects;
            this.dates = data.scheduleDates;
          },
          error:(response) => { }
        }).finally( () => this.loading = false )

      }
    }
  },
  created() {
    this.loadSchedules();
  },
  watch:{
    programId( ){
      this.loadSchedules( );
    }
  }

};
</script>

<style scoped>

  /* width of the entire scrollbar */
  #schedule-list::-webkit-scrollbar {
    width: 12px;
  }

  /* color of the tracking area */
  #schedule-list::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
  }

  /* color of the scroll thumb */
  #schedule-list::-webkit-scrollbar-thumb {
    width: 12px !important;
    /*background: linear-gradient(rgb(138, 172, 226), rgb(104, 226, 185), rgb(122, 167, 248), rgb(110, 222, 184), rgb(59, 130, 246), rgb(16, 185, 129), rgb(59, 130, 246));*/
    background: rgb(138, 172, 226);
  }


</style>
