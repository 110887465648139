<template>
  <div class="px-2 md:px-4 py-2 mb-8" v-if="schedules.length">
    <div class="border-b border-dashed mb-2 mt-4">{{ date }}</div>
    <schedule-item v-for="schedule in schedules" :schedule="schedule" :not-admitted-or-logged-in="notAdmittedOrLoggedIn"/>
  </div>
</template>

<script>
import Position from "../../exams/components/position";
import Btn from "../../../../components/btn";
import ScheduleItem from "./schedule-item";
export default {
  name: "schedules-with-date",
  components: {ScheduleItem, Btn, Position},
  props: {
    notAdmittedOrLoggedIn: {  type: Boolean, default: false },
    schedules:{ type:Array, default: [] },
    date:{ type:String, default: '' },
  }
}
</script>

<style scoped>

</style>