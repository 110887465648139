
export default {
    data() {
        return {
            formErrors: {},
            formMessage: '',
            formMessageStatus: 200,
            afterLoginActionCalled: false
        }
    },
    computed: {
        filtering: function (){
            return [
                {
                    key: 'subjectId',
                    value: this.subjectIds
                }
            ]
        },
        subjectIds:{
            get( ){
                if( typeof this.$route.query.subject_id == 'string' ) {
                    return this.$route.query.subject_id.split( ',' )
                }
                return this.subjects.map( subject => String(subject.id) );
            },
            set( id ){
                let subjectIds = this.subjectIds, ids = '',
                    currentId = String(id), ind = subjectIds.indexOf( currentId );

                if( ind > -1 ) {
                    currentId = '';
                    subjectIds.splice( ind, 1 );
                }

                ids = subjectIds.join( ',' ) + ','+ currentId;
                this.$router.push({ query: { ...this.$route.query, ...{ subject_id: ids.replace(/^\,|\,$/g,'') } } });
            }

        },
    },
    methods: { },

}